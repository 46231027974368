import React from "react"
import { Box } from "@chakra-ui/layout"

import ExpertProfile from "../components/expert_profile"

const ExpertProfileEditPage = props => {
  return (
    <Box position="relative">
      <ExpertProfile isExpert={true} />
    </Box>
  )
}

export default ExpertProfileEditPage
